var g = Object.defineProperty;
var b = (n, a, e) => a in n ? g(n, a, { enumerable: !0, configurable: !0, writable: !0, value: e }) : n[a] = e;
var i = (n, a, e) => b(n, typeof a != "symbol" ? a + "" : a, e);
const f = () => new Promise((n) => {
  var o;
  const e = new URL(window.location.href).searchParams.get("_envId");
  if (typeof e == "string") {
    n(e);
    return;
  }
  (o = window.pulse) == null || o.call(window, (t) => {
    n(t.getEnvironmentId());
  });
});
function m() {
  return `
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.22917 15.875L3.91667 10.5625C3.81944 10.4792 3.75 10.3889 3.70833 10.2917C3.66667 10.1944 3.64583 10.0972 3.64583 10C3.64583 9.90278 3.66667 9.80556 3.70833 9.70833C3.75 9.61111 3.81944 9.52083 3.91667 9.4375L9.22917 4.10417C9.36806 3.97917 9.52083 3.91667 9.6875 3.91667C9.86806 3.90278 10.0278 3.96528 10.1667 4.10417C10.2917 4.24306 10.3542 4.40278 10.3542 4.58333C10.3542 4.76389 10.2917 4.92361 10.1667 5.0625L5.875 9.33333H15.375C15.5417 9.33333 15.6944 9.40278 15.8333 9.54167C15.9722 9.66667 16.0417 9.81944 16.0417 10C16.0417 10.1806 15.9722 10.3403 15.8333 10.4792C15.6944 10.6042 15.5417 10.6667 15.375 10.6667H5.875L10.1667 14.9583C10.2917 15.0833 10.3542 15.2292 10.3542 15.3958C10.3681 15.5625 10.3056 15.7222 10.1667 15.875C10.0278 16 9.86806 16.0625 9.6875 16.0625C9.50695 16.0625 9.35417 16 9.22917 15.875Z" fill="currentColor"/>
</svg>
  
    `;
}
function d(n, a) {
  return `
    <div>
      <section class="consent-top">
        ${n ? '<button data-track-id="consent-or-pay-top-back" data-track-element="button" data-track-name="consent-or-pay-top-back" id="consent-top-link">' + m() + "<p>Tilbake</p></button>" : ""}
          <div class="consent-logo-container">
            <img alt="VG logo" width="22px" height="22px" src="https://static.privacy.schibsted.com/cmp/brands/VG.svg">
            </img><img alt="Schibsted logo" width="22px" height="22px" src="https://static.privacy.schibsted.com/cmp/schibsted_logo_primary_20231017.svg"></img>
          </div>
        </section>
      <h2>${a || "Velg hvordan du vil bruke VG"}</h2>
    </div>
  `;
}
function y() {
  return `
    <div id="info-container">
      ${d(!0)}
      <article>
        <h3>Cookieinstillinger</h3>
        <p>Schibsted Media AS trenger ditt samtykke for å kunne bruke cookies og lignende teknologi på nettstedet vårt.</p>
        <p>Når du klikker “Godta alle”, godtar du at vi og partnere bruker cookies og innsamlet data. Du kan justere innstillingene nedenfor. Du kan også justere hvordan Schibsteds partnere behandler personopplysninger under fanen “Leverandører”.</p>
        <p><a target="blank" href="https://info.privacy.schibsted.com/no/schibsted-norge-personvernerklaering/">Personvernerklæring</a></p>
      </article>
      <article id="customize-cookies">
        <h3>Tilpass cookies</h3>
        <div class="switch-container"><label for="personalized-ads">Personaliserte annonser. Alltid på eller <a id="consent-pay-link">betal for avslå.</a></label> 
          <button 
            class="consent-or-pay-switch" 
            aria-checked="true" 
            role="switch" 
            id="personalized-ads
            data-track-id="consent-or-pay-switch-ads"
            data-track-element-type="button"
            data-track-name="consent-or-pay-switch"
          "><span></button></div>
      </article>
      <article id="customize-all">
        <h3>Tilpasse cookies utover personaliserte annonser</h3>
        <div class="switch-container"><label for="personalization">Personalisering</label> 
          <button 
            class="consent-or-pay-switch" 
            aria-checked="false" 
            role="switch" 
            id="personalized-ads"
            data-track-id="consent-or-pay-switch-personalization"
            data-track-element-type="button"
            data-track-name="consent-or-pay-switch"
        ><span></button>
        </div>
        <div class="switch-container"><label for="analytics">Analyse/Produktutvikling</label> 
          <button 
            class="consent-or-pay-switch" 
            aria-checked="false" 
            role="switch" 
            id="personalized-ads"
            data-track-id="consent-or-pay-switch-analytics"
            data-track-element-type="button"
            data-track-name="consent-or-pay-switch"
          ><span></button></div>
        <div class="switch-container"><label for="marketing">Markedsføring</label> 
          <button 
            class="consent-or-pay-switch" 
            aria-checked="false" 
            role="switch" 
            id="personalized-ads"
            data-track-id="consent-or-pay-switch-marketing"
            data-track-element-type="button"
            data-track-name="consent-or-pay-switch"
          ><span></button></div>
      </article>
    </div>
    <div id="button-container">
      <button
        id="customize-save"
        data-track-id="consent-or-pay-customize-save"
        data-track-element-type="button"
        data-track-name="consent-or-pay-save"
      >Lagre</button>
      <button 
        id="customize-accept"
        data-track-id="consent-or-pay-customize-accept"
        data-track-element-type="button"
        data-track-name="consent-or-pay-consent"
      >Godta alle</button>
    </div>

  `;
}
const v = {
  consent: "https://iter.ly/w4v3j",
  customize: "https://iter.ly/qx98k",
  pay: "https://iter.ly/67ab8"
};
function w(n) {
  const a = v[n];
  return `
  <div class="consent-blue" id="info-container">
    ${d(!1, "Dette var en test")}
    <article>
      <p>Valget ditt ble ikke lagret under cookieinnstillinger, kun som et resultat i denne testen. Formålet med testen er å forstå hvordan vi kan samle personopplysninger på en måte som oppleves trygg og lett å forstå.</p>
      <p>Les mer om hvordan Schibsted Media behandler personopplysninger her:</p>
      <p><a target="blank" href="https://info.privacy.schibsted.com/no/schibsted-norge-personvernerklaering/">Personvernerklæring</a></p>
    </article></div>
  <div id="button-container">
      <a href=${a} target="_blank" id="finished-">Gi oss tilbakemeldinger</a>  
      <button id="close-consent-dialog">Lukk vinduet</button>
    </div>
  `;
}
function U() {
  return `
    <div id="info-container">
      ${d(!1)}
      <article id="intro-message">
        <h3>Kjære leser!</h3>
        <p>Vårt mål er at VGs nyhetsdekning skal være gratis for alle. Vi ønsker også å gi deg en så god opplevelse som mulig hver gang du besøker oss. Det klarer vi bare hvis du og andre lesere deler data med oss. Vi bruker denne informasjonen til å forbedre tjenestene og annonsene i VG - som finansierer journalistikken.</p>
      </article>
      <article>
        <h3>Godta alle cookies</h3>
        <p>Velg dette alternativet for å fortsette å bruke nettsiden kostnadsfritt og få en fullverdig leseopplevelse på VG. Ved å samtykke, godtar du at Schibsted Media AS og våre 38 partnere kan bruke cookies, enhets-IDer og andre data som lagres og/eller vi får tilgang til på din enhet fra VG, til å samle inn personopplysninger, slik som informasjon om hvilke sider du har besøkt, til produktutvikling, personlig tilpasning, markedsføring og annonsering.</p>
      </article>
      <article>
        <h3>Betal for å avslå personlig tilpassede annonser</h3>
        <p>Ved å velge dette alternativet vil du fortsatt se annonser på våre produkter, men annonsene vil ikke lenger være personaliserte av Schibsted eller våre partnere. Valget “Betal for å avslå personlig tilpassede annonser” knyttes ikke til din Schibsted-konto eller eventuelle abonnement du måtte ha hos oss.</p>
        <p>Du kan når som helst endre innstillingene dine via lenken “Cookieinnstillinger” på tjenestens side.</p>
        <p><a 
          target="blank" 
          data-track-id="consent-or-pay-intro-privacy"
          data-track-element-type="link"
          data-track-name="consent-or-pay-privacy"
          href="https://info.privacy.schibsted.com/no/schibsted-norge-personvernerklaering/"
        >Personvernerklæring</a></p>
      </article>
    </div>
    <div id="button-container">
      <button
         id="consent-button"
         data-track-id="consent-or-pay-intro-consent"
         data-track-element-type="button"
         data-track-name="consent-or-pay-consent"
      >Godta alle</button>
      <p><button
        id="customize"
        data-track-id="consent-or-pay-intro-customize"
        data-track-element-type="button"
        data-track-name="consent-or-pay-customize"
      >For å tilpasse cookies, klikk her</button></p>
      <button 
        id="pay-button"
        data-track-id="consent-or-pay-intro-pay"
        data-track-element-type="button"
        data-track-name="consent-or-pay-to-pay"
      >Betal for å avslå</button>
    </div>`;
}
function x() {
  return `
      <div id="info-container">
        ${d(!0)}
        <article>
          <h3>Betal for å avslå personlig tilpassede annonser</h3>
          <p>Ved å bli en "Betal for å avslå"-bruker vil vi ikke bruke dine aktivitetsdata til å vise deg personlig tilpassede annonser fra Schibsted eller noen av våre partnere.</p>
          <p>Denne betalingen er separat fra eventuelle andre abonnementer eller betalingsplaner du måtte ha hos oss.</p>
          <p><b>Pris: 49 kr per måned</b></p>
        </article>
    </div>
    <div id="button-container">
      <button 
        id="finish-pay"
        data-track-id="consent-or-pay-pay-finish"
        data-track-element-type="button"
        data-track-name="consent-or-pay-pay"
      >Fortsett til betaling</button>
      <button 
        id="pay-back"
        data-track-id="consent-or-pay-pay-back-button"
        data-track-element-type="button"
        data-track-name="consent-or-pay-back"
      >Gå tilbake</button>
    </div>
        `;
}
function F() {
  return `

  /* latin-ext */
@font-face {
    font-family: 'Schibsted Grotesk';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/schibstedgrotesk/v3/Jqz55SSPQuCQF3t8uOwiUL-taUTtap9IayojdSFOd1I.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
        U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Schibsted Grotesk';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/schibstedgrotesk/v3/Jqz55SSPQuCQF3t8uOwiUL-taUTtap9GayojdSFO.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: 'Schibsted Grotesk';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/schibstedgrotesk/v3/Jqz55SSPQuCQF3t8uOwiUL-taUTtap9IayojdSFOd1I.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
        U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Schibsted Grotesk';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/schibstedgrotesk/v3/Jqz55SSPQuCQF3t8uOwiUL-taUTtap9GayojdSFO.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

  #consent-or-pay-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999999;
  }

  #consent-or-pay-wrapper {
    font-family: 'Schibsted Grotesk', sans-serif !important;
    position: fixed;
    top: 80px;
    left: 0;
    bottom: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 12px;
    align-items: center;
    z-index: 999999;
  }

  
  .consent-or-pay-container {
    --consent-blue: #061A57;
    --consent-header-color: #020B23;
    --consent-text-color: #303130;
    --consent-link-color: #061A57;


    background-color: var(--background-primary, #fff);
    z-index: 1000;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-height: 80vh;

    p {
      color: var(--consent-text-color);
    }


    #consent-top-link {
      font-size: 13px;
      color: var(--consent-blue);
      display: flex;
      align-items: center;
      width: 74px;
    }

    .consent-top {
      display: flex;
      align-items: center;
      p {
        width: 100%;
        text-align: center;
        color: var(--consent-blue);
        font-weight: 700;
      }
    }

    .consent-logo-container {
      display: flex;
      gap: 8px;
    }

    article {
      display: flex;
      flex-direction: column;
      gap: 8px;

      a {
        color: var(--consent-link-color);
        text-decoration: underline;
      }
    }
    
    h3 {
      color: var(--consent-header-color);
      font-size: 16px;
      font-weight: 700;
    }


    #intro-message {
      padding: 16px;
      border: 1px solid #264BC512;
      
      color: var(--consent-blue);
      background: #F5F7FE;

      h3 {
        color: var(--consent-blue);
      }
    }
    
    
    #info-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 32px 16px 16px;

      overflow-y: scroll;  
    }
    

      h2 {
        font-size: 23px;
        color: var(--consent-header-color);
        margin-top: 6px;
      }

      #button-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: var(--space-l);
        gap: 12px;
        max-height: fit-content;
        margin: 0;
        box-shadow: 0px -3px 6px 0px #00000026;

        p {
          color: var(--consent-blue);
          font-size: 12,6px;
          line-height: 19px;
          margin-bottom: 10px;
          text-align: center;

          button, a {
            text-decoration: underline;
            color: var(--consent-header-color);
            font-weight: 500;
          }
        }
      }

      #button-container > button, #button-container > a {
        background-color: var(--consent-blue);
        height: 48px;
        border-radius: 24px;
        font-size: 16px;
        color: #F5F7FE;
        display: grid;
        place-items: center;
        font-weight: 700;
      }

      #button-container:has(a) > button, #button-container:has(a) > a {
        background-color: transparent;
        border: 2px solid #F5F7FE;
      }

      .consent-or-pay-switch {
        border: 2px solid var(--consent-blue);
        position: relative;
        height: 32px;
        border-radius: 16px;
        width: 60px;

        span {
          position: absolute;
          width:24px;
          height: 24px;
          left: 2px;
          border-radius: 50%;
          background-color: var(--consent-blue);
          transform: translateY(-50%);
        }
      }

      .switch-container {
      display: grid;
      grid-template-columns: 1fr 60px;

      .consent-or-pay-switch[aria-checked="true"] {
        background-color: #EEFCED;
        span {
        right: 2px;
        left: unset;
        background-color: #375F2B;
        }




      }


  `;
}
class C extends HTMLElement {
  constructor() {
    super();
    i(this, "backdrop", document.createElement("div"));
    i(this, "backLink", null);
    i(this, "container", document.createElement("div"));
    i(this, "currentView", "intro");
    i(this, "debugMode", null);
    // User buckets to show the consent or pay dialog to
    i(this, "elegibleUserBuckets", [3, 4, 5, 6, 7]);
    i(this, "envId", "");
    i(this, "normalTCF", null);
    i(this, "result", "");
    i(this, "segment", null);
    i(this, "tracking", "");
    i(this, "wrapper", document.createElement("div"));
  }
  changeView(e) {
    this.currentView = e, this.renderTest();
  }
  getCookie(e) {
    const t = new RegExp(e + "=([^;]+)").exec(document.cookie);
    return t && t.length === 2 ? t[1] : null;
  }
  async connectedCallback() {
    await this.validateUser() && (this.initiateConsentOrPay(), this.renderTest(), this.container.style.display = "fixed", document.documentElement.style.overflow = "hidden");
  }
  disconnectedCallback() {
    this.normalTCF && (this.normalTCF.style.left = "unset");
  }
  initiateConsentOrPay() {
    let e;
    switch (this.segment) {
      case "0":
        e = "a";
        break;
      case "1-7":
        e = "b";
        break;
      case "8+":
        e = "c";
        break;
      default:
        e = "d";
        break;
    }
    const o = new URL(window.location.href);
    o.searchParams.set("utm_term", e), window.history.replaceState({}, "", o.toString()), localStorage.setItem(
      "consent-or-pay-test",
      JSON.stringify({ hasSeen: this.segment, user: this.envId })
    );
    const t = new MutationObserver((l) => {
      l.forEach((s) => {
        if (s.type === "childList") {
          const r = document.querySelector(
            '[id^="sp_message_container"]'
          );
          r && (this.normalTCF = r, this.normalTCF.style.left = "-9999px", t.disconnect());
        }
      });
    });
    t.observe(document.body, { childList: !0, subtree: !0 }), this.tracking = `<track-element data-track-id="consent-or-pay-${e}" data-track-name="consent-or-pay-${e}" data-track-element-type="widget" data-track-impression><div></div></track-element>`;
    const c = document.createElement("style");
    c.innerHTML = F(), this.container.classList.add("consent-or-pay-container"), this.backdrop.id = "consent-or-pay-backdrop", this.wrapper.id = "consent-or-pay-wrapper", this.backdrop.innerHTML = this.tracking, this.appendChild(this.backdrop), this.appendChild(this.wrapper), this.wrapper.appendChild(this.container), this.appendChild(c);
  }
  async renderTest() {
    var l;
    switch (this.currentView) {
      case "intro":
        this.container.innerHTML = U();
        break;
      case "finished": {
        this.container.innerHTML = w(this.result), this.container.style.setProperty("--background-primary", "#061A57"), this.container.style.setProperty("--consent-header-color", "#F5F7FE"), this.container.style.setProperty("--consent-text-color", "#F5F7FE"), this.container.style.setProperty("--consent-link-color", "#F5F7FE"), localStorage.setItem(
          "consent-or-pay-test",
          JSON.stringify({ finished: !0 })
        );
        break;
      }
      case "customize": {
        this.container.innerHTML = y(), this.backLink = this.container.querySelector("#consent-top-link");
        const s = this.container.querySelector("#customize-all"), r = this.container.querySelector("#consent-pay-link"), p = this.container.querySelector("#customize-save"), h = this.container.querySelector("#customize-accept");
        s == null || s.querySelectorAll("button").forEach((u) => {
          u.addEventListener("click", () => {
            const k = u.getAttribute("aria-checked") === "true";
            u.setAttribute("aria-checked", k ? "false" : "true");
          });
        }), r == null || r.addEventListener("click", () => {
          this.changeView("pay");
        }), p == null || p.addEventListener("click", () => {
          this.result = "customize", this.changeView("finished");
        }), h == null || h.addEventListener("click", () => {
          this.result = "customize", this.changeView("finished");
        });
        break;
      }
      case "pay": {
        this.container.innerHTML = x(), this.backLink = this.container.querySelector("#consent-top-link");
        const s = this.container.querySelector("#finish-pay"), r = this.container.querySelector("#pay-back");
        r == null || r.addEventListener("click", () => {
          this.changeView("intro");
        }), s == null || s.addEventListener("click", () => {
          this.result = "pay", this.changeView("finished");
        });
        break;
      }
    }
    const e = this.querySelector("#consent-button"), o = this.querySelector("#pay-button"), t = this.querySelector("#customize"), c = this.querySelector("#close-consent-dialog");
    (l = this.backLink) == null || l.addEventListener("click", () => {
      this.changeView("intro");
    }), t == null || t.addEventListener("click", () => {
      this.changeView("customize");
    }), e == null || e.addEventListener("click", () => {
      this.result = "consent", this.changeView("finished");
    }), o == null || o.addEventListener("click", () => {
      this.changeView("pay");
    }), c == null || c.addEventListener("click", () => {
      this.remove(), document.documentElement.style.overflow = "auto";
    });
  }
  async validateUser() {
    if (!(document.documentElement.dataset.device === "mobile")) return;
    const o = new URLSearchParams(window.location.search);
    if (this.debugMode = o.get("ConsentTestDebugMode"), !this.debugMode) {
      const c = parseInt(this.getCookie("clientBucket") || "0");
      if (!this.elegibleUserBuckets.includes(c))
        return;
    }
    const t = JSON.parse(
      localStorage.getItem("consent-or-pay-test") || "{}"
    );
    if (!t.finished) {
      if (t.hasSeen)
        return this.segment = t.hasSeen, this.envId = await f(), !0;
      if (this.segment === null)
        if (this.debugMode)
          this.envId = "a6c3f6d2-a2c1-4e11-ab4c-ba895e12fd05", this.segment = "1-7";
        else
          return;
      if (this.segment !== null)
        return !0;
    }
  }
}
const E = () => {
  customElements.get("vg-consent-or-pay") || customElements.define("vg-consent-or-pay", C);
};
export {
  E as init
};
